export default {
  install: (app) => {
    function cmsModel(filename) {
      return `${import.meta.env.VITE_AXIOS_BASEURL}/models/${
        import.meta.env.VITE_PROJECT_ID
      }/${filename}/${filename}.glb`
    }

    app.config.globalProperties.$cmsModel = cmsModel
  },
}
